<template>
  <div class="container-fluid bg-color ">
    <section class="row justify-content-between align-items-center">
      <p class=" col-3 text-color mt-2">Copyright Vassar & Smith Technologies LLC</p>
      <p class="col-3 text-color mt-2">
          <img class="logo" src="../assets/veteranOwned.png" alt="">
        Veteran Owned</p>
    </section>

  </div>
</template>


<script>

export default {
  setup() {
    return {}
  }
};
</script>


<style scoped>
.bg-color {
  background-color: #214662;
}

.text-color {
  color: #ffffff;
}

.logo {
height: 5vh;
}
</style>