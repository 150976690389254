<template>

  <div id="team" class="container">
    <section class="row pt-3 justify-content-evenly">
      <div class="col-md-3 text ">
        <img class="img-fluid" src="../assets/Bryan.jpg" alt="">
        <h2>Bryan Vassar</h2>
        <h3>Founder/CEO</h3>
        <p class="textAlign">I Served in the United States Marine Corps for 8 years, and bring the same tenacity I had
          as an Infantry Marine to my Software Development Career. I graduated from Boise CodeWorks in 2023 with my full
          stack web design certificate. I would love to make your website dreams come true!</p>
      </div>
      <div class="col-md-3 text">
        <img class="img-fluid" src="../assets/Liam.jpg" alt="">
        <h2>Liam Smith</h2>
        <h3>Founder/COO</h3>
        <p>I am a Boise Native that has traveled and explored the world. I graduated from Boise CodeWorks in 2023 with
          my full stack web design certificate. I look forward to working together and building your dream web design.
        </p>
      </div>
      <div class="col-12 pt-4">
        <button class="btn text-dark button-style-two m-2 " data-bs-toggle="modal" data-bs-target="#contactForm">Contact
          Us</button>
      </div>
    </section>
  </div>
</template>


<script>
import { onMounted } from "vue";



export default {

  setup() {

    onMounted(() => {
    })

    return {
    }
  },


};
</script>


<style scoped>
.button-style-two {
  border: 2px solid #08494B;
  z-index: 3;
}

.textAlign {
  text-align: start;
}
</style>