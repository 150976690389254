<template>
  <div id="home" class="container-fluid">
    <!-- //SECTION HeroImage -->
    <section class="row justify-content-end heroImg">
      <div class="col-md-4 p-3">
        <!-- Mission Statement Section -->
        <section class="row p-2 pt-4 text-light rounded shadow bgStyle">
          <h1 class="fs-4 text-uppercase fw-bold">Boise-Based Custom Web Design and Development</h1>
          <!-- Call to Action Button -->
          <div>
            <button class="btn text-light button-style-two m-2" data-bs-toggle="modal" data-bs-target="#contactForm">
              Contact Us
            </button>
          </div>
        </section>
      </div>
    </section>
    <!-- //SECTION - Mission Statement -->
    <section class="row justify-content-center p-5">
      <div class="col-md-6 missionBox dflex align-content-center">
        <h2 class="fs-3 missionStyle">
          <span class="fw-bold">Our Mission</span> is to bridge the gap between enterprise solutions & app builder platforms. We want to start with you from the beginning to scale and grow with you.
        </h2>
      </div>
    </section>
    <!-- //SECTION - Company Value Pillars -->
    <section class="row p-5 pillarRow">
      <div class="col-12">
        <h2 class="fs-3 pb-5">
          We Empower Growth with Custom Web Design and Scalable Solutions in Boise with our core values of:
        </h2>
      </div>
      <div class="col-md-6">
        <section class="row flex-column align-content-center">
          <!-- Creativity Column -->
          <div class="col-md-6 text-center p-2 pillarWrap1" ref="pillarWrap1">
            <div class="pillar">
              <h3 class="text-uppercase fw-bold fs-5">Creativity</h3>
              <p class="fs-6 text-start">Innovative solutions crafted with a fresh perspective, ensuring your website stands out.</p>
            </div>
          </div>

          <!-- Quality Column -->
          <div class="col-md-6 text-center p-2 pillarWrap2" ref="pillarWrap2">
            <div class="pillar">
              <h3 class="text-uppercase fw-bold fs-5">Quality</h3>
              <p class="fs-6 text-start">Uncompromising standards that deliver robust, reliable, and aesthetically pleasing websites.</p>
            </div>
          </div>

          <!-- Partnership Column -->
          <div class="col-md-6 text-center p-2 pillarWrap3" ref="pillarWrap3">
            <div class="pillar">
              <h3 class="text-uppercase fw-bold fs-5">Partnership</h3>
              <p class="fs-6 text-start">Working together to bring your vision to life with a deep understanding of your goals.</p>
            </div>
          </div>

          <!-- Performance Column -->
          <div class="col-md-6 text-center p-2 pillarWrap4" ref="pillarWrap4">
            <div class="pillar">
              <h3 class="text-uppercase fw-bold fs-5">Performance</h3>
              <p class="fs-6 text-start">Websites built for speed, usability, and top search engine rankings to maximize growth.</p>
            </div>
          </div>
        </section>
      </div>
      <div class="col-md-6 dflex justify-content-center align-content-center">
        <section class="row align-items-center justify-content-center">
          <div class="col-md-4 text-center">
            <p class="shadow rounded p-5 ceoQuote">"We do not believe that there is a limit to what our clients can achieve. We want to meet their needs in whatever season of business they are currently in."</p>
          </div>
          <div class="col-md-7">
            <img class="img-fluid rounded shadow ceoImg" src="https://images.unsplash.com/photo-1657632843433-e6a8b7451ac6?q=80&w=2024&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
          </div>
        </section>
      </div>
    </section>
    <!-- //SECTION - Call to action -->
    <section class="row p-5 justify-content-evenly CEOStyle">
      <div class="col-md-3">
        <p class="fs-6 fw-bold">Ready to Start Your Project?</p>
        <button class="btn button-style-dark m-2" data-bs-toggle="modal" data-bs-target="#contactForm">
          Get a Free Estimate
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    const pillars = [
      { ref: this.$refs.pillarWrap1, delay: 0 },
      { ref: this.$refs.pillarWrap2, delay: 200 },
      { ref: this.$refs.pillarWrap3, delay: 400 },
      { ref: this.$refs.pillarWrap4, delay: 600 },
    ];

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const pillar = pillars.find(p => p.ref === entry.target);
          setTimeout(() => {
            entry.target.classList.add('slide-in'); // Add the class to trigger the animation
            observer.unobserve(entry.target); // Stop observing after animation is triggered
          }, pillar.delay);
        }
      });
    });

    pillars.forEach((pillar) => {
      observer.observe(pillar.ref);
    });
  }
};
</script>

<style scoped>
.heroImg {
  min-height: 80dvh;
  background-image: url("https://images.unsplash.com/photo-1465244554671-e501f19a3bb3?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-blend-mode: lighten;
  background-size: cover;
  background-repeat: no-repeat;
}

.bgStyle {
  background-color: #ffffff36;
}

.button-style-two {
  border: 2px solid #ffffff;
  z-index: 3;
}

.button-style-dark {
  border: 2px solid #000000;
  z-index: 3;
}
.missionStyle {
  font-style: italic;
}
.missionBox {
  border: 5px solid #1e0300;
  border-radius: 15px;
  padding: 35px;
  min-height: 35dvh;
}

/* Value Pillars */
.pillar {
  border-radius: 10px;
  box-shadow: 5px 5px 5px 5px #00000031;
  padding: 25px;
  min-height: 25dvh;
  background-color: #ffffff;
  color: #000000;
}
.pillarWrap1,
.pillarWrap2,
.pillarWrap3,
.pillarWrap4 {
  transform: translateX(-100%); /* Start completely off the left side of the screen */
  opacity: 0; /* Initially hidden */
  transition: transform 1s ease-out, opacity 1s ease-out; /* Smooth transition for transform and opacity */
}

.pillarWrap1.slide-in {
  transform: translateX(-14vw); /* End position when visible */
  opacity: 1; /* Fully visible */
}

.pillarWrap2.slide-in {
  transform: translateX(-5vw); /* Adjusted end position */
  opacity: 1;
}

.pillarWrap3.slide-in {
  transform: translateX(5vw); /* Adjusted end position */
  opacity: 1;
}

.pillarWrap4.slide-in {
  transform: translateX(14vw); /* Adjusted end position */
  opacity: 1;
}

.pillarRow {
  background-color: #e8e8e8;
}

.CEOStyle {
  background-color: #e8e8e8;
}
.ceoQuote {
  position: relative;
  transform: translateX(40px);
  background-color: #ffffff;
}

.ceoImg {
  /* Your styles here */
}

/* Image Alt Text for Accessibility and SEO */
img[alt="Boise web design"] {
  content: "Creative website design services in Boise, Idaho";
}
</style>
