<template>
  <div id="services" class="container-fluid">
    <!-- Growth Journey Timeline -->
    <section class="growth-journey-container">
      <!-- Stage 1: Basic Solutions for New Businesses -->
      <div class="stage stage1" ref="stage1">
        <div class="stage-content">
          <h2 class="stage-title text-uppercase fw-bold">Start with Us: Basic Solutions</h2>
          <p>Perfect for new businesses. We offer affordable app builder stores or custom static websites that provide a professional web presence without breaking the bank.</p>
          <p><strong>Benefits:</strong> Establish a digital presence quickly and affordably with a solid foundation.</p>
          <p><strong>Pricing Incentive:</strong> The cost of this stage is applied as a discount toward the next stage!</p>
          <!-- Button that triggers the existing modal -->
          <button class="btn text-dark button-style-two m-2" data-bs-toggle="modal" data-bs-target="#contactForm">Get Started</button>
        </div>
      </div>

      <!-- Connector Line -->
      <div class="connector" ref="connector1"></div>

      <!-- Stage 2: Growing Together -->
      <div class="stage stage2" ref="stage2">
        <div class="stage-content">
          <h2 class="stage-title text-uppercase fw-bold">Grow with Us: Advanced Web Development</h2>
          <p>As your business grows, so should your website. We build comprehensive websites with databases, dynamic content, and robust backend solutions to support your growth.</p>
          <p><strong>Benefits:</strong> Expand functionality and improve user engagement while leveraging the foundation you've already built.</p>
          <p><strong>Pricing Incentive:</strong> Apply the cost from Stage 1 as a discount to this stage!</p>
          <!-- Button that triggers the existing modal -->
          <button class="btn text-dark button-style-two m-2" data-bs-toggle="modal" data-bs-target="#contactForm">Learn More</button>
        </div>
      </div>

      <!-- Connector Line -->
      <div class="connector" ref="connector2"></div>

      <!-- Stage 3: Expanding Horizons -->
      <div class="stage stage3" ref="stage3">
        <div class="stage-content">
          <h2 class="stage-title text-uppercase fw-bold">Expand with Us: Apps & Secondary Solutions</h2>
          <p>Take your business to the next level with custom apps or additional web solutions that complement your main site, enhance customer engagement, and drive new growth.</p>
          <p><strong>Benefits:</strong> Broaden your digital footprint with innovative apps and secondary solutions tailored to your needs.</p>
          <p><strong>Pricing Incentive:</strong> Continue applying your past investment as a discount to this stage!</p>
          <!-- Button that triggers the existing modal -->
          <button class="btn text-dark button-style-two m-2" data-bs-toggle="modal" data-bs-target="#contactForm">Start Now</button>
        </div>
      </div>

      <!-- Connector Line -->
      <div class="connector" ref="connector3"></div>

      <!-- Stage 4: Reaching New Heights -->
      <div class="stage stage4" ref="stage4">
        <div class="stage-content">
          <h2 class="stage-title text-uppercase fw-bold">Enterprise-Level Solutions</h2>
          <p>Finally, achieve the pinnacle of digital success with enterprise-level solutions that provide maximum scalability, security, and performance. Tailor-made for businesses ready to dominate their market.</p>
          <p><strong>Benefits:</strong> Enjoy a powerful, scalable solution that supports your long-term growth and market leadership.</p>
          <p><strong>Pricing Incentive:</strong> Apply all previous stage discounts to your enterprise solution build!</p>
          <!-- Button that triggers the existing modal -->
          <button class="btn text-dark button-style-two m-2" data-bs-toggle="modal" data-bs-target="#contactForm">Get a Free Consultation</button>
        </div>
      </div>
    </section>

    <!-- Call to Action -->
    <section class="row p-5 justify-content-evenly CEOStyle">
      <div class="col-md-3">
        <p class="fs-6 fw-bold">Ready to Start Your Growth Journey?</p>
        <button class="btn button-style-dark m-2" data-bs-toggle="modal" data-bs-target="#contactForm">
          Let's Begin Together
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    const stages = [
      { ref: this.$refs.stage1, delay: 0 },
      { ref: this.$refs.connector1, delay: 100 },
      { ref: this.$refs.stage2, delay: 200 },
      { ref: this.$refs.connector2, delay: 300 },
      { ref: this.$refs.stage3, delay: 400 },
      { ref: this.$refs.connector3, delay: 500 },
      { ref: this.$refs.stage4, delay: 600 },
    ];

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const stage = stages.find((s) => s.ref === entry.target);
          setTimeout(() => {
            entry.target.classList.add('slide-in'); // Trigger the animation
            observer.unobserve(entry.target); // Stop observing once triggered
          }, stage.delay);
        }
      });
    });

    stages.forEach((stage) => {
      observer.observe(stage.ref);
    });
  },
};
</script>

<style scoped>
/* Static Background Image for the Page with Opacity */
#services {
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1516864351034-bf734fd2c77e?q=80&w=2041&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-attachment: fixed; /* Keeps the background image fixed while scrolling */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#services::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  z-index: 0; /* Ensures the overlay is behind the content */
}

.growth-journey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  position: relative;
  z-index: 1; /* Ensures content is above the background image and overlay */
}

/* Stage Styling */
.stage {
  width: 70%;
  padding: 30px;
  margin: 40px 0;
  background-color: rgba(245, 245, 245, 0.9); /* Slight transparency to show the background image through */
  border: 2px solid #1e0300;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  opacity: 0;
  transition: transform 1s ease-out, opacity 1s ease-out;
}

.stage-content {
  text-align: center;
}

.connector {
  width: 5px;
  height: 50px;
  background-color: #1e0300;
  opacity: 0;
  transform: scaleY(0);
  transition: transform 1s ease-out, opacity 1s ease-out;
}

/* Slide-in Animation */
.stage.slide-in,
.connector.slide-in {
  transform: translateY(0) scaleY(1);
  opacity: 1;
}

.button-style-two {
  border: 2px solid #000000;
  z-index: 3;
}

.button-style-dark {
  border: 2px solid #000000;
  z-index: 3;
}

.CEOStyle {
  background-color: #e8e8e8;
}
</style>
